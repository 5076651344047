import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import styled from "styled-components"
import { Link } from "gatsby"
import { keyframes } from "styled-components"

function Nordstrom() {
  return (
    <Layout>
      <SEO title="Past works" />
      <Wrapper>
        <CloseBtn>
          <Link to="/">
            <img src="images/icons/cancel.png" alt="cancel"></img>
          </Link>
        </CloseBtn>
        <ContentWrapper>
          <Bg2>
            <ContentHeaderWrapper> Nordstrom</ContentHeaderWrapper>
          </Bg2>
        </ContentWrapper>
        <Bg1>
          <ContentBodyWrapper>
            <Header>Overview</Header>
            <Body>
              The purpose of this study is to evaluate the usability of
              Nordstrom’s mobile website. its “buy online, pick up in store”
              (BOPUS) feature, and its product search and navigation functions.
              The current solution contains the same features as its desktop
              counterpart, but with a modified layout and experience more
              suitable for handheld devices. The mobile website is intended to
              be used by customers that either cannot download the Nordstrom iOS
              app or prefer to shop using a mobile browser.
              <Body>
                My team and I recruited participants (5 female, 2 male, ages
                20-30) with varying shopping habits and preferences for the
                study.
              </Body>
              <Body>
                Using a mobile testing lab, we instructed participants to
                complete four scenarios that were designer to provide quantitive
                and qualitative insights about the research questions.
              </Body>
            </Body>
          </ContentBodyWrapper>
        </Bg1>

        <Bg2>
          <ContentBodyWrapper>
            <Header>Primary Research Questions</Header>
            <Body>
              - How discoverable and accessible is BOPUS? - How efficiently can
              users change the pickup location of an item in the shopping bag? -
              How easily and successfully do user find the product they are
              looking for? - What general usability issues do users encounter
              while completing our tasks?
            </Body>
          </ContentBodyWrapper>
        </Bg2>

        <Bg1>
          <ContentBodyWrapper>
            <Header>
              Qualitative Finding - Video Recording, Dedicated Note Taker
            </Header>
            <Body>
              - How discoverable and accessible is BOPUS? - How efficiently can
              users change the pickup location of an item in the shopping bag? -
              How easily and successfully do user find the product they are
              looking for? - What general usability issues do users encounter
              while completing our tasks?
            </Body>
          </ContentBodyWrapper>
          <ContentBodyWrapper>
            <img src="images/past/usertesting.gif" alt="User Testing"></img>
          </ContentBodyWrapper>
        </Bg1>

        <Bg1>
          <ContentBodyWrapper>
            <Header>
              Qualitative Finding - Video Recording, Dedicated Note Taker
            </Header>
            <img src="images/past/data.png" alt="data"></img>
            <img src="images/past/data2.png" alt="data2"></img>
          </ContentBodyWrapper>
        </Bg1>

        <Bg1>
          <ContentBodyWrapper>
            <Header>Result / Outcome </Header>
            <Body>
              In the result, we were able to find some finding and came up with
              design recommendations for improving the mobile browsing and
              purchasing experience. (see image below)
            </Body>
            <img src="images/past/301.gif" alt="301"></img>
            <img src="images/past/101.gif" alt="101"></img>
            <img src="images/past/201.gif" alt="201"></img>
          </ContentBodyWrapper>
        </Bg1>

        <Bg2>
          <ContentMenuWrapper>
            <Link to="/rooy">
              <Menu>
                <span role="img" aria-label="next">
                  ⬅
                </span>
                <span> Previous project</span>
              </Menu>
            </Link>
            <Link to="/tradein">
              <Menu>
                Next project{" "}
                <span role="img" aria-label="next">
                  ⮕
                </span>
              </Menu>
            </Link>
          </ContentMenuWrapper>
        </Bg2>
      </Wrapper>
    </Layout>
  )
}

export default Nordstrom

const animation = keyframes`
from {opacity: 0; transform: translateY(10px);};
to {opacity: 1; transform: translateY(0px);};
`

const Wrapper = styled.div`
  width: 100%;
`

const ContentWrapper = styled.div`
  background: url("/images/past/nordstrom.jpg");
  height: 600px;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: end;

  @media (max-width: 944px) {
    height: 400px;
  }
`
const ContentHeaderWrapper = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 72px;
  line-height: 100%;
  color: #fff;
  padding-bottom: 86px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  animation: ${animation} 1s 0.2s forwards;
  opacity: 0;
`

const ContentBodyWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  img {
    max-width: 100%;
    height: auto;
    margin-bottom: 24px;
  }
`

const Bg1 = styled.div`
  width: 100%;
  padding: 86px 200px;
  background-color: #fff;

  @media (max-width: 944px) {
    padding: 24px;
  }
`

const Bg2 = styled.div`
  width: 100%;
  padding: 86px 200px;

  @media (max-width: 944px) {
    padding: 24px;
  }
`

const Header = styled.div`
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 130%;
  /* or 31px */

  color: #000;
  margin-bottom: 24px;
`

const Body = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 150%;
  /* or 23px */

  /* Text */

  color: #000000;
  margin-bottom: 24px;
`

const ContentMenuWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
`

const Menu = styled.div`
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 130%;
  /* or 31px */

  color: #2d577d;

  :hover {
    opacity: 0.8;
  }
`

const CloseBtn = styled.div`
  display: block;
  position: fixed;
  margin: 35px 30px;

  :hover {
    opacity: 0.4;
  }

  @media (max-width: 944px) {
    display: none;
  }

  img {
    width: 50px;
    height: 50px;
    opacity: 0.7;
  }
`
